/*
    Upload
*/
fp-s3-upload {
    display: block;
    
    
}

.fp-s3-upload-container {
    
    .fpop-media-layout-gt-sm & {
        max-height: 360px;
    }
}


.fp-s3-upload-list {
    
    .fpop-media-layout-gt-sm & {
        margin-top: 0;
        padding-left: 0.6em;
        
        md-list {
            overflow-y: scroll;
            overflow-x: hidden;
        }
    }

    margin-top: 0.6em;
    padding-left: 0;


    border-radius: 10px;
    background-color: #fbfbfb;




    
    
    
    md-list-item {
        width: 100%;

        .file-name {
            padding: 0 0 0.2em 0;
        }
        
        .md-list-item-text {
            margin-top: 12px;
        }


        md-progress-linear {
            display: block;
            margin: 1em 0;
        }        
    }
    
    .upload-toolbar {
        background-color: #f0f0f0;
    }
    
}




.fp-s3-upload-target {
    position: relative;
    border: 2px dashed #999;
    background-color: #fbfbfb;
    border-radius: 10px;
    display: inline-block;
    position: default;
    
    label {
        width: 100%;
        height: 100%;
        padding: 1em;
        display: block;
        cursor: pointer;
        position: relative;
        
        
        &:focus,
        &:active,
        &.drag-over {
            background-size: 30px 30px;
            background-image: linear-gradient( -45deg, #F6F6F6 25%, transparent 25%, transparent 50%, #F6F6F6 50%, #F6F6F6 75%, transparent 75%, transparent );
            animation: stripes 2s linear infinite;
            
            svg {
                fill: #4caf50;
            }
        }    
    }
    
    input[type='file'] {
        // visibility: hidden;
        opacity: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }
    

    
    span {
        display: block;
        font-size: 12px;
        line-height: 1.2em;
        text-align: center;
        // font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        color: #777;
    }
    
    
    md-icon {
        display: block;
        
        width: 100%;
        height: auto;
        
        max-width: 96px;
        max-height: 96px;
        margin: 0 auto;
        padding: 0;
    }
    
    svg {
        display: block;
        fill: #ccc;
        margin: 0;
        padding: 0;
    }

}







